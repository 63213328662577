import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from './Catalyst/navbar';
import { Dropdown, DropdownButton } from './Catalyst/dropdown';
import { Avatar } from './Catalyst/avatar';

import AccountDropdownMenu from './AccountDropdownMenu';
import { getUserInitials } from '~/tools/form-data';
import { useRouteLoaderData } from '@remix-run/react';
import { RootLoaderResponse } from '~/root/types';

export default function Component() {
  const data = useRouteLoaderData<RootLoaderResponse>('root');

  if (!data?.user) {
    return <></>;
  }

  return (
    <Navbar>
      <NavbarSpacer />
      <NavbarSection>
        <Dropdown>
          <DropdownButton as={NavbarItem}>
            <Avatar
              initials={getUserInitials(data.user.firstName, data.user.lastName)}
              src={data.user.avatar}
              alt="avatar"
              square
            />
          </DropdownButton>
          <AccountDropdownMenu anchor="bottom end" />
        </Dropdown>
      </NavbarSection>
    </Navbar>
  );
}
