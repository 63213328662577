import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import * as Sentry from '@sentry/remix';

import { Heading } from '~/components/Catalyst/heading';
import { Button } from '~/components/Catalyst/button';
import { Text } from '~/components/Catalyst/text';

export const RootErrorBoundary = () => {
  const error = useRouteError() as Error;

  Sentry.captureRemixErrorBoundaryError(error);

  const errorMessages: Record<number, [string, string]> = {
    404: ['Page not found', "Sorry, we couldn't find the page you're looking for."],
    500: [
      'Something went wrong!',
      'An error occurred while processing your request. Please try again'
    ]
  };

  let info = errorMessages[500][1];
  let status: string | undefined;
  let message = errorMessages[500][0];
  let showHome = false;

  if (isRouteErrorResponse(error)) {
    let msg = errorMessages[error.status];
    if (error.status >= 500) {
      msg = errorMessages[500];
    }
    message = msg?.[0] || error.statusText;
    status = error.status.toString();
    info = msg?.[1] || error.data;
    showHome = true;
  }

  return (
    <main className="grid min-h-[100vh] place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        {status && <Text>{status}</Text>}
        <Heading>
          <span className="text-3xl font-bold tracking-tight sm:text-5xl">{message}</span>
        </Heading>
        <Text className="mt-3">
          <span className="text-lg font-semibold">{info}</span>
        </Text>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {showHome && <Button href="/">Go back home</Button>}
          <Button outline href="mailto:support@uptimeinsight.com">
            Contact support <span aria-hidden="true">&rarr;</span>
          </Button>
        </div>
      </div>
    </main>
  );
};
