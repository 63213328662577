import { useRef } from 'react';

import { DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu } from './Catalyst/dropdown';
import {
  ArrowRightStartOnRectangleIcon,
  ShieldCheckIcon,
  LightBulbIcon,
  UserCircleIcon
} from '@heroicons/react/16/solid';

export default function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
  const signOutForm = useRef<HTMLFormElement>(null);

  return (
    <>
      <form ref={signOutForm} method="POST" action="/" className="hidden">
        <input type="hidden" value="sign-out" name="global-form-id" />
      </form>
      <DropdownMenu className="min-w-64" anchor={anchor}>
        <DropdownItem className="cursor-pointer" href="/account">
          <UserCircleIcon />
          <DropdownLabel>My account</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem className="cursor-pointer" href="#">
          <ShieldCheckIcon />
          <DropdownLabel>Privacy policy</DropdownLabel>
        </DropdownItem>
        <DropdownItem className="cursor-pointer" href="#">
          <LightBulbIcon />
          <DropdownLabel>Share feedback</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem onClick={() => signOutForm.current!.submit()} className="cursor-pointer">
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel>Sign out</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </>
  );
}
