import { LinksFunction } from '@remix-run/cloudflare';

export const rootLinks: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: 'https://rsms.me/inter/inter.css'
    }
  ];
};
