import { useLocation, useRouteLoaderData } from '@remix-run/react';
import {
  QuestionMarkCircleIcon,
  SparklesIcon,
  GlobeEuropeAfricaIcon,
  CubeTransparentIcon,
  SignalIcon,
  BoltIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid';

import { ChevronDownIcon, Cog8ToothIcon, PlusIcon, ChevronUpIcon } from '@heroicons/react/16/solid';

import { Avatar } from './Catalyst/avatar';
import { Badge } from './Catalyst/badge';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer
} from './Catalyst/sidebar';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu
} from './Catalyst/dropdown';

import { RootLoaderResponse } from '~/root/types';
import { getInitials, getUserInitials } from '~/tools/form-data';
import AccountDropdownMenu from './AccountDropdownMenu';
import { Subscription } from '~/types/teams';

const buttons = [
  {
    name: 'Monitors',
    route: '/%team%/monitors',
    Icon: GlobeEuropeAfricaIcon
  },
  {
    name: 'Invocations',
    route: '/%team%/invocations',
    Icon: BoltIcon
  },
  {
    name: 'Integrations',
    route: '/%team%/integrations',
    Icon: CubeTransparentIcon
  },
  {
    name: 'Status pages',
    route: '/%team%/status-pages',
    Icon: SignalIcon
  },
  {
    name: 'Incidents',
    route: '/%team%/incidents',
    Icon: ExclamationTriangleIcon
  }
];

export default function Component() {
  const data = useRouteLoaderData<RootLoaderResponse>('root');
  const location = useLocation();

  if (!data?.selectedTeam?.id) {
    return <></>;
  }

  return (
    <Sidebar>
      <SidebarHeader>
        <Dropdown>
          <DropdownButton as={SidebarItem} className="lg:mb-2.5">
            <Avatar
              square
              src={data.selectedTeam.logo}
              initials={getInitials(data.selectedTeam.name)}
            />
            <SidebarLabel>{data.selectedTeam.name}</SidebarLabel>
            <ChevronDownIcon />
          </DropdownButton>
          <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
            {data.selectedTeam.permissions?.settingsAdmin && (
              <DropdownItem className="cursor-pointer" href={`/${data.selectedTeam.id}/settings`}>
                <Cog8ToothIcon />
                <DropdownLabel>Settings</DropdownLabel>
              </DropdownItem>
            )}
            {data.teams.length > 1 && (
              <>
                <DropdownDivider />
                {data.teams
                  .filter(({ id }) => id !== data.selectedTeam!.id)
                  .map(({ id, name }) => (
                    <DropdownItem
                      href={location.pathname.replace(data.selectedTeam?.id || 'undefined', id)}
                      key={id}
                    >
                      <Avatar slot="icon" square src={undefined} initials={getInitials(name)} />
                      <DropdownLabel>{name}</DropdownLabel>
                    </DropdownItem>
                  ))}
                <DropdownDivider />
              </>
            )}
            <DropdownItem className="cursor-pointer" href="/setup">
              <PlusIcon />
              <DropdownLabel>New organization</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {buttons.map(({ name, route, Icon }) => (
            <SidebarItem
              current={location.pathname.startsWith(
                route.replaceAll('%team%', data.selectedTeam!.id)
              )}
              key={route}
              href={route.replaceAll('%team%', data.selectedTeam!.id)}
            >
              <Icon />
              <SidebarLabel>{name}</SidebarLabel>
            </SidebarItem>
          ))}
        </SidebarSection>
        {/* <SidebarSection className="max-lg:hidden">
          <SidebarHeading>Live Incidents</SidebarHeading>
          <SidebarItem href="/events/1">test1</SidebarItem>
          <SidebarItem href="/events/2">test2</SidebarItem>
          <SidebarItem href="/events/3">test3</SidebarItem>
        </SidebarSection> */}
        <SidebarSpacer />
        <SidebarSection>
          {getSubscriptionBadge(data.selectedTeam?.subscription)}
          <SidebarItem href="/support">
            <QuestionMarkCircleIcon />
            <SidebarLabel>Support</SidebarLabel>
          </SidebarItem>
          <SidebarItem href="/changelog">
            <SparklesIcon />
            <SidebarLabel>Changelog</SidebarLabel>
          </SidebarItem>
        </SidebarSection>
      </SidebarBody>

      <SidebarFooter className="max-lg:hidden">
        <Dropdown>
          <DropdownButton as={SidebarItem}>
            <span className="flex min-w-0 items-center gap-3">
              <Avatar
                initials={getUserInitials(data.user.firstName, data.user.lastName)}
                src={data.user.avatar}
                className="size-10"
                alt="avatar"
                square
              />
              <span className="min-w-0">
                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                  {data.user.firstName} {data.user.lastName}
                </span>
                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                  {data.user.email}
                </span>
              </span>
            </span>
            <ChevronUpIcon />
          </DropdownButton>
          <AccountDropdownMenu anchor="top start" />
        </Dropdown>
      </SidebarFooter>
    </Sidebar>
  );
}

const getSubscriptionBadge = (subscription?: Subscription) => {
  if (!subscription?.plan) {
    return (
      <Badge color="red" className="mb-4 text-center">
        We&apos;ve detected a problem with your subscription. Please contact our support team so we
        can help you resolve it.
      </Badge>
    );
  }

  if (subscription.status === 'expired') {
    return (
      <Badge color="orange" className="mb-4 text-center">
        Your subscription has expired. Your organization is currently read-only and scheduled for
        deletion
      </Badge>
    );
  }

  if (subscription.status === 'pending') {
    return (
      <Badge color="yellow" className="mb-4 text-center">
        Your subscription is currently pending activation
      </Badge>
    );
  }

  return <></>;
};
