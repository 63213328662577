import { Links, Meta, Scripts, ScrollRestoration, useRouteLoaderData } from '@remix-run/react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useEffect, useMemo, useRef } from 'react';

import { getBrowserType, getDeviceType } from '~/tools/parsers';
import { RootLoaderResponse } from './types';
import { Heading } from '~/components/Catalyst/heading';
import { Strong, Text } from '~/components/Catalyst/text';
import { Button } from '~/components/Catalyst/button';

export const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const rootData = useRouteLoaderData<RootLoaderResponse>('root');

  const growthbook = useMemo(() => {
    if (rootData?.isUserBanned) {
      return null;
    }

    return new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: rootData?.config.growthbookClientKey,
      disableJsInjection: true
    });
  }, [rootData?.config?.growthbookClientKey, rootData?.isUserBanned]);

  useEffect(() => {
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (!growthbook) {
      return;
    }

    growthbook.init({ streaming: true }).then(() => {
      if (!rootData?.user) {
        growthbook.setAttributes({
          deviceType: getDeviceType(navigator?.userAgent)
        });
        return;
      }

      growthbook.setAttributes({
        deviceType: getDeviceType(navigator?.userAgent),
        emailDomain: rootData.user.email.split('@')?.[1],
        browser: getBrowserType(navigator?.userAgent),
        changesEmail: !!rootData.user.newEmail,
        id: rootData.user.id
      });
    });
  }, [growthbook, rootData?.user]);

  return (
    <html
      className="bg-white text-zinc-950 antialiased lg:bg-zinc-50 dark:bg-zinc-900 dark:text-white dark:lg:bg-zinc-950"
      lang="en"
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="select-none">
        {rootData?.isUserBanned ? (
          <Banned date={new Date(rootData.bannedUntil!)} />
        ) : (
          <GrowthBookProvider growthbook={growthbook!}>{children}</GrowthBookProvider>
        )}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};

const Banned = ({ date }: { date: Date }) => {
  const signOutForm = useRef<HTMLFormElement>(null);
  return (
    <>
      <form ref={signOutForm} method="POST" action="/" className="hidden">
        <input type="hidden" value="sign-out" name="global-form-id" />
      </form>
      <main className="grid min-h-[100vh] place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <Text>Banned</Text>
          <Heading>
            <span className="text-3xl font-bold tracking-tight sm:text-5xl">
              Account Temporarily Suspended
            </span>
          </Heading>
          <Text className="mx-auto mt-3 max-w-xl">
            <span className="text-lg">
              Your account has been temporarily suspended. This suspension will be lifted on{' '}
              <Strong>{date.toLocaleString()}</Strong>. You will be able to access your account
              again at that time.
            </span>
          </Text>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button outline href="mailto:support@uptimeinsight.com">
              Contact support <span aria-hidden="true">&rarr;</span>
            </Button>
            <Button onClick={() => signOutForm.current!.submit()} className="cursor-pointer">
              Sign out
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};
