import { useLoaderData, useLocation, Outlet } from '@remix-run/react';
import * as Sentry from '@sentry/remix';

import './tailwind.css';

import { SidebarLayout } from './components/Catalyst/sidebar-layout';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';

import { RootErrorBoundary } from './root/errorBoundary';
import { RootLoaderResponse } from './root/types';
import { RootLayout } from './root/layout';
import { rootLinks } from './root/links';
import rootAction from './root/action';
import rootLoader from './root/loader';

export const ErrorBoundary = RootErrorBoundary;
export const Layout = RootLayout;
export const loader = rootLoader;
export const action = rootAction;
export const links = rootLinks;

function App() {
  let showSidebar = !['/sign-in', '/sign-up', '/confirm', '/accept'].includes(
    useLocation().pathname
  );
  const data = useLoaderData<RootLoaderResponse>();

  if (showSidebar && data.teams.length === 0) {
    showSidebar = false;
  }

  return (
    <>
      {showSidebar ? (
        <SidebarLayout navbar={<Navbar />} sidebar={<Sidebar />}>
          <Outlet />
        </SidebarLayout>
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default Sentry.withSentry(App, {
  wrapWithErrorBoundary: false
});
